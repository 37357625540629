import React from "react";
import { PortableText } from "./PortableText";

const PressKitContainer = ({ downloads, title, _rawContent }) => {

	return (
		<div className='w-4/5 mx-auto rounded-sm text-center my-[10%] md:my-[5%] md:rounded-lg bg-primary'>
			<h5 className='h4 -translate-y-[40%] md:-translate-y-[50%]'>{title}</h5>
			<div className='pb-[5%] pt-[1%] mx-auto w-3/4 md:w-4/5 md:pb-[4%]'>
				<PortableText className='press rich-text' blocks={_rawContent} />
				{downloads.length > 0 &&
					downloads?.map(({ displayName, document }) => (
						<div className="press rich-text mt-4" key={document?.asset?._id}>
							<a href={`${document?.asset?.url}?dl=`} target="_blank" rel="noopener noreferrer" download>
								{displayName}
							</a>
						</div>
					))}
			</div>
		</div>
	)
}

export default PressKitContainer;