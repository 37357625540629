import { graphql, useStaticQuery } from 'gatsby'

export const usePressKit = () => {
	const data = useStaticQuery(graphql`
		query {
			press: sanityPressKit {
				hero {
					headline
					subHeadline
					_rawImage
					image {
						...Image
						alt
					}
				},
				intro,
				pressResources {
					title,
					_rawContent,
					downloads {
            displayName,
            document {
              asset {
                url,
                _id,
								originalFilename
							}
						}
					}
				}
			}
		}
	`)
	return data
}
