import React from "react";
import Hero from "../components/Hero";
import { usePressKit } from "../hooks/usePressKit";
import PressKitContainer from "../components/PressKitContainer";

const PressKit = () => {
	const { press } = usePressKit();
	if (!press) return null;
	const { hero, intro, pressResources } = press;
	const isPressResources = pressResources && pressResources.length > 0;
	const heroProps = { ...hero, isPress: true }

	return (
		<div>
			{hero && <Hero {...heroProps} />}
			<p className='body !normal-case w-4/5 mx-auto max-w-5xl pb-[2%] leading-tight text-center'>
				{intro}
			</p>
			{isPressResources &&
				pressResources.map((item, index) =>
					<PressKitContainer key={index} {...item} />
				)
			}
		</div>
	)
}

export default PressKit;